const nav = document.querySelector('.nav-mobile')
const navDesktop = document.querySelector('.nav-desktop')
const navButton = document.querySelector('.hamburger')
const spanYear = document.querySelector('.footer__copy-year')
const inputName = document.querySelector('#name')
const inputEmail = document.querySelector('#email')
const message = document.querySelector('#msg')
const sendBtn = document.querySelector('.contact__btn')
const popup = document.querySelector('.contact__popup')
const cookieBox = document.querySelector('.cookie-box')
const cookieBtn = document.querySelector('.cookie-btn')
const navLink = document.querySelectorAll('.nav-desktop__item')

const main = () => {
	addCurrentYear()
}

const addCurrentYear = () => {
	const year = new Date().getFullYear()
	spanYear.innerText = year
}

const activeLink = window.location.href

navLink.forEach((link) => {
	if (link.href === activeLink) {
		link.classList.add('active')
	}
})

const navbarActive = () => {
	navButton.classList.toggle('is-active')
	nav.classList.toggle('navbar-active')
	document.body.classList.toggle('sticky-body')
}

const navShadow = () => {
	if (window.scrollY > navDesktop.offsetHeight) {
		navDesktop.classList.add('shadow')
	} else {
		navDesktop.classList.remove('shadow')
	}
}

const verifyForm = (e) => {
	checkForm(inputs)
	verifyMail(email)
	vefiryCheck(checkbox)
}

const inputs = [inputName, inputEmail, message]

const checkForm = () => {
	inputs.forEach((inp) => {
		if (inp.value === '') {
			showError(inp)
		} else {
			deleteError(inp)
		}
	})
}

const showError = (input) => {
	const formBox = input.parentElement
	formBox.classList.add('error')
}

const deleteError = (input) => {
	const formBox = input.parentElement
	formBox.classList.remove('error')
}

const verifyMail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	if (re.test(email.value)) {
		deleteError(email)
	} else {
		showError(email)
	}
}

console.log(document.location.search)

if (document.location.search === '?mail_status=sent') {
	popup.classList.add('show-popup')

	setTimeout(() => {
		popup.classList.remove('show-popup')
	}, 3000)
}

const showCookie = () => {
	const cookieEaten = localStorage.getItem('cookie')
	if (cookieEaten) {
		cookieBox.classList.add('hide')
	}
}

const hideCookieAlert = () => {
	localStorage.setItem('cookie', 'true')
	cookieBox.classList.add('hide')
}

showCookie()

window.addEventListener('scroll', navShadow)
cookieBtn.addEventListener('click', hideCookieAlert)
navButton.addEventListener('click', navbarActive)
document.addEventListener('DOMContentLoaded', main)
sendBtn.addEventListener('click', verifyForm)
